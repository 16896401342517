body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#fg {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

#bg {
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(20px);
}

#trama-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000059;
  background-image: url(/public/images/trama.png);
}

.loader--default {
  display: inline-block;
  position: relative;
  height: 64px;
  width: 64px;
}

.loader--default div {
  position: absolute;
  animation: loader--default 1.2s linear infinite;
  border-radius: 50%;
  height: 6px;
  width: 6px;
}

.loader--default div:nth-child(1) {
  animation-delay: 0s;
  top: 29px;
  left: 53px;
}

.loader--default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 18px;
  left: 50px;
}

.loader--default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 9px;
  left: 41px;
}

.loader--default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 6px;
  left: 29px;
}

.loader--default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 9px;
  left: 18px;
}

.loader--default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 18px;
  left: 9px;
}

.loader--default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 29px;
  left: 6px;
}

.loader--default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 41px;
  left: 9px;
}

.loader--default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 50px;
  left: 18px;
}

.loader--default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 53px;
  left: 29px;
}

.loader--default div:nth-child(11) {
  animation-delay: -1s;
  top: 50px;
  left: 41px;
}

.loader--default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 41px;
  left: 50px;
}

@keyframes loader--default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}
